<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('StudyPlans')"
      icon="mdi-account"
  >
    <h1>Work Programs</h1>
  </sub-page>
</template>

<script>
export default {
  name: "workPrograms"
}
</script>

<style scoped>

</style>